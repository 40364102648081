
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class RepairItem extends Vue {
		@Prop(String) readonly keyString!:string;
		@Prop(String) readonly timeString!:string;
		@Prop(Number) readonly id!:number;
		@Prop(String) readonly describe!:string;
		@Prop(Number) readonly status!:number;
	}
